/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Sugar from 'sugar';
Sugar.extend();

import 'tiny-slider/dist/tiny-slider.css';

import 'magnific-popup';
import 'magnific-popup/dist/magnific-popup.css';

import 'pikaday/scss/pikaday.scss';

import 'jquery-ujs';

import pageLoad from '@/helpers/page_load';
window.pageLoad = pageLoad;

import pageUnload from '@/helpers/page_unload';
window.pageUnload = pageUnload;

import Turbolinks from 'turbolinks';

import '@/helpers/100vh_var';
import csrf from '@/helpers/csrf';

window.axios = require('axios').create({
  headers: { ...csrf().headers, 'X-Requested-With': 'XMLHttpRequest' }
});

const requireVendor = require.context('@/vendor', false);
requireVendor.keys().forEach(requireVendor);

const requirePages = require.context('@/pages', true);
requirePages.keys().forEach(requirePages);

const requireArticles = require.context('@/articles', true);
requireArticles.keys().forEach(requireArticles);

const requireBlocks = require.context('@/blocks', true);
requireBlocks.keys().forEach(requireBlocks);

Turbolinks.start();
import bindings from '@/helpers/bindings';

$(document).on(Object.keys(bindings).join(' '), e => {
  bindings[e.type].forEach(group => {
    let bodyClasses;
    if (group.conditions.length && (group.conditions[0][0] === '.')) {
      bodyClasses = group.conditions
        .filter(v => v[0] === '.')
        .map(v => `p-${v.slice(1)} `);
    } else {
      bodyClasses = [];
    }

    if (!group.conditions.length) {
      group.callback();
    } else if (bodyClasses && bodyClasses.some(v => document.body.className.indexOf(v) !== -1)) {
      group.callback();
    } else if (group.conditions.some(v => document.body.id === v)) {
      group.callback();
    }
  });
});

import '@/application';
import '../stylesheets/application.sass';

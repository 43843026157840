import delay from 'delay';
import bind from 'bind-decorator';
import View from './view';

const EVENTS = [
  'change',
  'keyup',
  'paste',
  'composable_form:validate',
  'component:update'
];

export default class ComposableForm extends View {
  initialize({ fields = [], components = [] }) {
    this.$fields = fields.map(field => this.$(field));
    this.components = components;

    this.on(this.events, this.validate);
    this.validate();
  }

  get isValid() {
    return this.$fields.every($input => this.isInputValid($input[0])) &&
      this.components.every(component => component.isValid);
  }

  get $submit() {
    return this.$('input[type=submit]');
  }

  get events() {
    return EVENTS.join(' ');
  }

  @bind
  async validate() {
    await delay(1);
    this.$submit.prop('disabled', !this.isValid);
  }

  destroy() {
    this.off(this.events, this.validate);
    this.components.forEach(component => {
      if (typeof component.destroy === 'function') {
        component.destroy();
      }
    });
  }

  isInputValid(input) {
    return input.disabled || (input.type === 'checkbox' ?
      input.checked :
      !!input.value
    );
  }
}

import ComposableForm from '@/views/composable_form';
import PhoneArea from '@/views/phone_area';

pageLoad('users_show', 'users_update', async () => {
  new ComposableForm('.b-form.edit_user', {
    fields: ['#user_email']
  });
  new ComposableForm('.b-form.edit_profile', {
    components: [new PhoneArea('.b-phone_area')]
  });

  const { default: Uploader } = await import(
    /* webpackChunkName: "gallery_uploader" */ '@/admin/views/uploader'
  );
  const { default: Gallery } = await import(
    /* webpackChunkName: "gallery_uploader" */ '@/admin/views/gallery'
  );

  const gallery = new Gallery();
  new Uploader({
    $uploadButton: $('#upload'),
    $targetInput: $('#profile_image_image'),
    presignUrl: '/selfstorage/profile_images/cache/presign/'
  });

  $('#new_profile_image').on('ajax:success', (e, { html, error }) => {
    $('#upload').removeClass('btn-loading');

    if (error) {
      alert(error);
    } else {
      gallery.add(html);
    }
  });

  $('.b-gallery').on('ajax:before', '.delete', e => {
    gallery.remove(
      $(e.target).closest('.b-collection_image_item')
    );
  });
});

import imagesloaded from 'imagesloaded';

import { isMobile } from '@/helpers/mobile_detect';

export async function initStorages() {
  const { default: Swiper } = await import('swiper');

  return new Swiper('.storages-swiper-container', {
    wrapperClass: 'fc-storages',
    slideClass: 'f-column',
    grabCursor: true,
    slidesPerView: 'auto',
    slidesPerColumn: 2,
    spaceBetween: 0
  });
}

function initGallery() {
  const $photos = $('.b-photos .photo:not([data-magnific_popup])');
  if (!$photos.length) { return; }

  $photos
    .attr('data-magnific_popup', '')
    .addClass('magnific-popup')
    .magnificPopup({
      type: 'image',
      closeOnContentClick: true,
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1]
      }
    });
}

export function initPhotos() {
  const $photos = $('.b-photos .photo');
  let swiper = null;

  if ($photos.length) {
    imagesloaded($photos.toArray(), async () => {
      initGallery();

      if ($photos.length > 3) {
        $('.b-photos').addClass('swiper-v8');

        const [{ default: Swiper }, { Navigation }] = await Promise.all([
          import('swiper'),
          import('swiper/modules')
        ]);

        swiper = new Swiper('.b-photos', {
          grabCursor: true,
          slidesPerView: isMobile() ? 2 : 'auto',
          spaceBetween: isMobile() ? 12 : 40,
          wrapperClass: 'photos-slider',
          slideClass: 'photo',
          modules: [Navigation],
          navigation: {
            nextEl: '.photos-slider-next',
            prevEl: '.photos-slider-prev'
          },
          loop: true,
          on: {
            slideChange: initGallery
          }
        });
        swiper.changeLanguageDirection(document.documentElement.getAttribute('dir'));
      }
    });
  }

  return swiper;
}

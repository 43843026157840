pageLoad('vacancies_apply', async () => {
  $('form').on('submit', e => {
    let isValid = true;
    const isPrivacyAccepted = $('.privacy input').is(':checked');
    const formNode = e.currentTarget;

    $(e.currentTarget).find('.b-input:not(.optional)').each((_index, node) => {
      const $node = $(node);
      const $input = $node.find('input,select,textarea');
      const isInputValid = !!$input.val().trim();

      $node.toggleClass('field_with_errors', !isInputValid);
      if (!isInputValid) {
        isValid = false;
      }
    });

    $('.error-message.validation-message', formNode)
      .toggleClass('hidden', isValid);
    $('.error-message.privacy-message', formNode)
      .toggleClass('hidden', isPrivacyAccepted);

    if (!isValid || !isPrivacyAccepted) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  });

  $('form input[type=file]').on('change', e => {
    const files = e.target.files;

    if (files.length) {
      $('.upload .label').css('opacity', 0);
      $('.upload .filename')
        .removeClass('hidden')
        .html(files[0].name);
    }
  });

  if ($('.is-show-modal').length) {
    const { thanksModal } = await import(
      /* webpackChunkName: "modal" */
      '@/views/modal/thanks_modal'
    );
    thanksModal();
  }
});

import bind from 'bind-decorator';
import delay from 'delay';
import { createPopper } from '@popperjs/core/lib/popper-lite';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';
import offset from '@popperjs/core/lib/modifiers/offset';
import arrow from '@popperjs/core/lib/modifiers/arrow';

import View from './view';
import { isMobile } from '@/helpers/mobile_detect';

export default class PopperTooltip extends View {
  isShown = false;
  popper = null;
  $arrow = null;
  $close = null;
  $shade = null;

  initialize() {
    this.$trigger.on('click', this.toggle);
  }

  get $trigger() {
    return this.$node;
  }

  get $tooltip() {
    return this.$node.next();
  }

  @bind
  toggle() {
    if (this.isShown) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    this.isShown = true;
    this.$tooltip.addClass('is-visible-stage-1');

    this.$arrow = $(`
      <div data-popper-arrow />
    `)
      .prependTo(this.$tooltip);
    this.$close = $(`
      <button class="close-icon">
        <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 2L2 16M2 2L16 16" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    `)
      .prependTo(this.$tooltip)
      .on('click', this.onClose);

    this.$shade = $(`
      <div class="b-tooltip-shade" />
    `)
      .on('click', this.hide)
      .insertAfter(this.$tooltip);

    requestAnimationFrame(() => {
      this.$shade.addClass('is-visible');

      if (!isMobile()) {
        this.popper = createPopper(
          this.$trigger[0],
          this.$tooltip[0],
          {
            placement: 'left',
            modifiers: [preventOverflow, offset, arrow, {
              name: 'preventOverflow',
              options: { padding: 10 }
            }, {
              name: 'offset',
              options: { offset: [0, 10] }
            }]
          }
        );
      }
      this.$tooltip.addClass('is-visible-stage-2');
    });

    $(document).one('turbolinks:before-cache', this.hide);
  }

  @bind
  onClose(e) {
    e.preventDefault();
    this.hide();
  }

  @bind
  async hide() {
    if (!this.isShown) { return; }

    this.isShown = false;

    this.$tooltip.removeClass('is-visible-stage-2');
    if (this.$shade) {
      this.$shade.removeClass('is-visible');
    }
    await delay(300); // animation duration
    this.$tooltip.removeClass('is-visible-stage-1');

    if (this.isShown) { return; }

    this.$arrow.remove();
    this.$close.remove();

    if (this.$shade) {
      this.$shade.remove();
    }

    if (this.popper) {
      this.popper.destroy();
      this.popper = null;
    }
  }
}

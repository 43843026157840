import delay from 'delay';
import Cookies from 'js-cookie';

$(document).on('turbolinks:load', () => {
  $('.b-cookies-popup button').on('click', async ({ currentTarget }) => {
    const $popup = $(currentTarget).closest('.b-cookies-popup');
    const cookiesKey = $popup.data('cookies_key');

    Cookies.set(cookiesKey, '1', { expires: 730, path: '/' });
    $popup.addClass('is-closing');

    await delay(500);
    $popup.remove();
  });
});

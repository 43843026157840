import { isMobile } from '@/helpers/mobile_detect';

let swiper = null;

pageUnload('pages_how_to_choose_box', () => {
  if (swiper) {
    swiper.destroy();
    swiper = null;
  }
});

pageLoad('pages_how_to_choose_box', async () => {
  if (isMobile()) {
    const [{ default: Swiper }, { Pagination }] = await Promise.all([
      import('swiper'),
      import('swiper/modules')
    ]);

    swiper = new Swiper('.slide_2 .slides', {
      wrapperClass: 'slides-wrapper',
      slideClass: 'slide-vertical-outer',
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 0,
      modules: [Pagination],
      pagination: {
        el: '.b-slider-pagination',
        modifierClass: 'slider-pagination-',
        bulletClass: 'slider-pagination-bullet',
        bulletActiveClass: 'slider-pagination-bullet-active',
        clickable: true
      },
      on: {
        slideChange() {
          setSwiperIndex(this.activeIndex);
        }
      }
    });
    swiper.changeLanguageDirection(document.documentElement.getAttribute('dir'));

    setSwiperIndex(0);
  }
});

function setSwiperIndex(index) {
  $('.slide_2 .slides .b-slider-pagination').attr('data-index', index);
}

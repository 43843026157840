export default function setCaretToEnd(inputNode) {
  inputNode.focus();

  if (typeof inputNode.selectionStart === 'number') {
    inputNode.selectionStart = inputNode.selectionEnd = inputNode.value.length;
  } else if (typeof inputNode.createTextRange !== 'undefined') {
    const range = inputNode.createTextRange();
    range.collapse(false);
    range.select();
  }
}

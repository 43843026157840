import bind from 'bind-decorator';

import View from './view';

export default class Tabs extends View {
  get $activeTab() {
    return this.$('.is-active');
  }

  @bind
  insertMarker() {
    this.$marker = $('<div class="marker"></div>').appendTo(this.$('.inner'));
    this.syncMarker();
  }

  @bind
  syncMarker() {
    this.$marker.css({
      transform: `translateX(${this.$activeTab.position().left}px)`,
      width: this.$activeTab.outerWidth()
    });
  }
}

let app;

pageLoad('assistants_show', async () => {
  const { createApp } = await import(/* webpackChunkName: "vue" */ 'vue');
  const { default: App } = await import('@/vue/assistant/index');

  const appNode = document.querySelector('#assistant_app');
  app = createApp(App, {
    chatUrl: appNode.getAttribute('data-assistant_url')
  });

  app.mount(appNode);
});

pageUnload('assistants_show', () => {
  if (app) {
    app.unmount();
    app = null;
  }
});

export default function(event) {
  if (window.ENV == 'development') {
    console.log('track_vk', event); // eslint-disable-line no-console
  }

  if (window.location.host === 'kladovkin.ru') {
    // NOTE: Временно отправляем события/цели в VK
    //       id взят из app/views/application/kladovkin/_vk_pixel.
    //       Если в VK реклама будет не выгодная, то нужно это удалить
    //       вместе с пикселем.
    if (window._tmr) {
      window._tmr.push({ type: 'reachGoal', id: '3416788', goal: event });
    }
  }
}

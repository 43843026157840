import Turbolinks from 'turbolinks';
import delay from 'delay';

import axios from '@/helpers/axios';
import { isMobile } from '@/helpers/mobile_detect';

const REFRESH_INTERAL = 4000;

pageLoad('payments_qr', async () => {
  delay(REFRESH_INTERAL).then(processQrStatus);

  if (isMobile()) {
    const qrUrl = $('.qr-container [data-fetch_url]').data('qr_url');

    if (qrUrl) {
      await delay(250);
      Turbolinks.visit(qrUrl);
    }
  }
});

async function processQrStatus() {
  const $container = $('.qr-container');
  const fetchUrl = $container.find('[data-fetch_url]').data('fetch_url');
  const redirectUrl = $container.find('[data-redirect_url]').data('redirect_url');

  if (fetchUrl) {
    const result = await axios.get(fetchUrl);

    $container.html(result.data.html);

    delay(REFRESH_INTERAL).then(processQrStatus);
  } else if (redirectUrl) {
    Turbolinks.visit(redirectUrl);
  }
}

let swiper = null;

pageLoad('pages_root', async () => {
  if (!document.body.className.includes('v4')) { return; }

  const [{ default: Swiper }, { Autoplay }] = await Promise.all([
    import('swiper'),
    import('swiper/modules')
  ]);

  swiper = new Swiper('article.slide_12 .swiper-v8', {
    wrapperClass: 'slides',
    slideClass: 'slide',
    grabCursor: true,
    slidesPerView: 'auto',
    spaceBetween: 0,
    loop: true,
    modules: [Autoplay],
    speed: 10000,
    freeMode: true,
    resistance: false,
    autoplay: {
      delay: 0
    }
  });
});

pageUnload('pages_root', () => {
  if (swiper) {
    swiper.destroy();
    swiper = null;
  }
});

import { isMobile } from '@/helpers/mobile_detect';

let swiper = null;

$(document).on('turbolinks:before-cache', () => {
  if (swiper) {
    swiper.destroy();
    swiper = null;
  }
});

pageLoad('pages_root', async () => {
  if (!document.body.className.includes('v4')) { return; }

  const swiperNode = document.body.querySelector('article.slide_6_2 .swiper-v8');
  if (!swiperNode) { return; }

  const [{ default: Swiper }, { Navigation }] = await Promise.all([
    import('swiper'),
    import('swiper/modules')
  ]);

  swiper = new Swiper(swiperNode, {
    grabCursor: true,
    wrapperClass: 'boxes',
    slideClass: 'box',
    slidesPerView: isMobile() ? 1 : 4,
    spaceBetween: 24,
    // modules: [Navigation, Pagination],
    modules: [Navigation],
    navigation: {
      nextEl: '.next',
      prevEl: '.prev',
      disabledClass: 'is-disabled'
    }
  });
  swiper.changeLanguageDirection(document.documentElement.getAttribute('dir'));
});

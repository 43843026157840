import { throttle } from 'throttle-debounce';
import delay from 'delay';

let throttledUpdateVh = null;

export default () => {
  throttledUpdateVh = throttle(350, updateVh);
  window.addEventListener('resize', scheduleUpdateVh);
  window.addEventListener('focusin', scheduleUpdateVh);
  window.addEventListener('focusout', scheduleUpdateVh);
};

function updateVh() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
async function scheduleUpdateVh() {
  await delay();
  throttledUpdateVh();
  await delay(450);
  throttledUpdateVh();
}

import View from './view';

export default class PrivacyNote extends View {
  initialize() {
    this.$checkbox = this.$('input[type=checkbox]');
  }

  get isValid() {
    return this.$checkbox.prop('checked');
  }
}

import Tabs from '@/views/tabs';

let swiper = null;

pageLoad('pages_root', async () => {
  if (!document.body.className.includes('v4')) { return; }

  const [{ default: Swiper }, { EffectFade, Pagination }] = await Promise.all([
    import('swiper'),
    import('swiper/modules')
  ]);

  const $article = $('article.slide_10');
  const paginationData = $article.find('.swiper').data('pagination');
  const tabs = $article.find('.b-tabs').toArray().map(node => new Tabs(node));

  swiper = new Swiper($article.find('.swiper')[0], {
    modules: [EffectFade, Pagination],
    wrapperClass: 'swiper-wrapper',
    slideClass: 'swiper-slide',
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {
      el: $article.find('.b-tabs .inner')[0],
      clickable: true,
      bulletActiveClass: 'is-active',
      bulletClass: 'tab',
      renderBullet: (index, className) => (
        `<div class="tab ${paginationData[index][0]} ${className}">` +
          `<span>${paginationData[index][1]}</span></div>`
      )
    },
    on: {
      afterInit: () => {
        syncTabs(tabs);
        tabs.forEach(tab => tab.insertMarker());
      },
      slideChange: () => {
        syncTabs(tabs);
        tabs.forEach(tab => tab.syncMarker());
      }
    }
  });

  $('.show-more', $article).on('click', ({ currentTarget }) => {
    $(currentTarget).closest('.swiper-slide').addClass('is-expanded');
  });

  $('.tab', tabs[1].node).on('click', ({ currentTarget }) => (
    swiper.slideTo($(currentTarget).index())
  ));
});

pageUnload('pages_root', () => {
  if (swiper) {
    swiper.destroy();
    swiper = null;
  }
});

function syncTabs(tabs) {
  const index = $('article.slide_10 .swiper-slide:first-child .tab.is-active')
    .index();

  const $tabs = $('article.slide_10 .swiper-slide:last-child .tab');

  $tabs.removeClass('is-active');
  $tabs.eq(index).addClass('is-active');
}

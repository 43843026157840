import ComposableForm from '@/views/composable_form';
import PrivacyNote from '@/views/privacy_note';
import PhoneArea from '@/views/phone_area';

pageLoad('registrations_new', 'registrations_create', () => {
  if (!document.body.className.includes('p-devise')) { return; }

  new ComposableForm('.b-form', {
    fields: ['#user_email', '#user_password'],
    components: [
      new PhoneArea('.b-phone_area'),
      new PrivacyNote('.b-privacy_note')
    ]
  });
});

pageLoad('sessions_new', 'sessions_create', () => {
  if (!document.body.className.includes('p-devise')) { return; }

  new ComposableForm('.b-form', {
    fields: ['#user_email', '#user_password']
  });
});

pageLoad('passwords_new', 'passwords_create', () => {
  new ComposableForm('.b-form', {
    fields: ['#user_email']
  });
});

pageLoad('passwords_edit', 'passwords_update', () => {
  new ComposableForm('.b-form', {
    fields: ['#user_password', '#user_password_confirmation']
  });
});

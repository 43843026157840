import SweetScroll from '@/helpers/sweet_scroll';

pageLoad('pages_root', () => {
  if (!document.body.className.includes('v4')) { return; }

  $('article.slide_7 .load-more').on('click', () => {
    const scrollY = window.scrollY;
    $('article.slide_7').addClass('is-loaded_more');
    requestAnimationFrame(async () => {
      window.scrollTo(0, scrollY);
      (await SweetScroll.asyncInstance()).toElement(
        document.querySelector('article.slide_7 .reviews .column:nth-child(2)'),
        { duration: 750, offset: -100 }
      );
    });
  });
});

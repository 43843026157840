import bind from 'bind-decorator';

import View from './view';
import { animatedCollapse, animatedExpand } from '@/helpers/animated';

export default class WorldMap extends View {
  initialize() {
    this.$('.country-list .country-item .name')
      .on('click', this.selectCountryItem);
  }

  @bind
  selectCountryItem({ currentTarget }) {
    const node = currentTarget.parentNode;

    if (node.classList.contains('is-expanded')) {
      animatedCollapse(currentTarget.nextSibling);
      node.classList.remove('is-expanded');
    } else {
      animatedExpand(currentTarget.nextSibling);
      node.classList.add('is-expanded');
    }
  }
}

let swiper = null;

pageLoad('pages_root', async () => {
  if (!document.body.className.includes('v3')) { return; }

  const { default: Swiper } = await import('swiper');

  swiper = new Swiper('.swiper-v8', {
    wrapperClass: 'slides',
    slideClass: 'slide-wrapper',
    grabCursor: true,
    slidesPerView: 'auto',
    spaceBetween: 0
  });
});

pageUnload('pages_root', () => {
  if (swiper) {
    swiper.destroy();
    swiper = null;
  }
});

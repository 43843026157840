pageLoad('tariffs_show', 'tariffs_new', () => {
  $('.tariffs .inner .tariff').on('click', ({ currentTarget }) => {
    const $tariff = $(currentTarget);
    $tariff.parent().find('.tariff').removeClass('active');
    $tariff.addClass('active');

    const $next = $('a.next.select-tariff');
    $next.removeClass('disabled');

    const selectedTariff = $tariff.data('kind').replace('period_', '');
    const nextUrl = $next.data('href').replace(/\/tariffs\/\w+\//, `/tariffs/${selectedTariff}/`);
    $next.attr('href', nextUrl);
  });
});

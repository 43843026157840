import showModal from '@/utils/show_modal';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

export function makeModal({
  $node,
  $trigger,
  cssClass,
  isDestroyOnHide = false
}) {
  const $modal = $(`
    <div class="b-modal${cssClass ? ' ' + cssClass : ''}">
      <div class="modal-shade"></div>
      <div class="modal-inner">
        <button class="modal-close" />
      </div>
    </div>
  `);
  $node.appendTo($modal.children('.modal-inner'));
  $modal.appendTo(document.body);

  const modal = showModal({
    $modal,
    $trigger
  });

  $modal.find('.modal-shade').on('click', modal.hide);
  $modal.find('.modal-close').on('click', modal.hide);

  disablePageScroll();

  const extendedModal = {
    ...modal,
    destroy() {
      enablePageScroll();
      modal.destroy();
      $modal.remove();
    }
  };

  $modal.on('modal:hide', enablePageScroll);

  if (isDestroyOnHide) {
    $modal.one('modal:hide', extendedModal.destroy);
  }

  return extendedModal;
}

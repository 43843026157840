import showModal from '@/utils/show_modal';
import makeVueModal from '@/views/modal/make_vue_modal';
import { isMobile } from '@/helpers/mobile_detect';

let profileMenuModal = null;
let contactsModal = null;
let localeSwitcherModal = null;

$(document).on('turbolinks:load', () => {
  if (!document.body.className.includes('v4')) { return; }

  $('.b-top_menu .profile').on('click', e => {
    if (!isMobile()) {
      return;
    }

    e.preventDefault();
    profileMenuModal ||= showModal({
      $modal: $('.b-top_menu .popup'),
      $trigger: $('.b-top_menu .profile'),
      show() {
        $('.b-top_menu .popup').addClass('is-active');
      },
      hide() {
        $('.b-top_menu .popup').removeClass('is-active');
      },
      isHidden() {
        return !$('.b-top_menu .popup').hasClass('is-active');
      }
    })
      .show();
    $('.b-top_menu .close').on('click', profileMenuModal.hide);
    $('.b-top_menu .profile').on('click', e => {
    });
  });

  $('.b-top_menu .contacts-modal-trigger').one('click', async ({ currentTarget }) => {
    const { default: vueComponent } = await import('@/vue/modals/contacts');
    contactsModal ||= makeVueModal({
      trigger: currentTarget,
      vueComponent
    });
  });

  $('.b-top_menu .locale_switcher-modal-trigger').one('click', async ({ currentTarget }) => {
    const { default: vueComponent } = await import('@/vue/modals/locale_switcher');
    localeSwitcherModal ||= makeVueModal({
      trigger: currentTarget,
      vueComponent
    });
  });
});

$(document).on('turbolinks:before-cache', () => {
  if (profileMenuModal) {
    profileMenuModal.destroy();
    profileMenuModal = null;
  }

  if (contactsModal) {
    contactsModal.destroy();
    contactsModal = null;
  }

  if (localeSwitcherModal) {
    localeSwitcherModal.destroy();
    localeSwitcherModal = null;
  }
});

let swiper = null;

$(document).on('turbolinks:before-cache', () => {
  if (swiper) {
    swiper.destroy();
    swiper = null;
  }
});

$(document).on('turbolinks:load', async () => {
  const node = document.querySelector('article.care');
  if (!node) { return; }

  const [{ default: Swiper }, { Navigation, Pagination }] = await Promise.all([
    import('swiper'),
    import('swiper/modules')
  ]);

  swiper = new Swiper('article.care .slides', {
    wrapperClass: 'slides-wrapper',
    slideClass: 'slide',
    grabCursor: true,
    slidesPerView: 'auto',
    spaceBetween: 0,
    modules: [Navigation, Pagination],
    navigation: {
      nextEl: '.b-slider-next',
      prevEl: '.b-slider-prev',
      disabledClass: 'is-disabled'
    },
    pagination: {
      el: '.b-slider-pagination',
      modifierClass: 'slider-pagination-',
      bulletClass: 'slider-pagination-bullet',
      bulletActiveClass: 'slider-pagination-bullet-active',
      clickable: true
    },
    loop: true
  });
  swiper.changeLanguageDirection(document.documentElement.getAttribute('dir'));
});

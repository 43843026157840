import { animatedCollapse, animatedExpand } from '@/helpers/animated';

$(document).on('turbolinks:load', () => {
  const node = document.querySelector('article.v3.got_questions');
  if (!node) { return; }

  $('.question .label', node).on('click', ({ currentTarget }) => {
    const node = currentTarget.parentNode;

    if (node.classList.contains('is-expanded')) {
      animatedCollapse(currentTarget.nextSibling);
      node.classList.remove('is-expanded');
    } else {
      animatedExpand(currentTarget.nextSibling);
      node.classList.add('is-expanded');
    }
  });
});

pageLoad('faqs_show', () => {
  $('.questions .contents li').on('click', ({ currentTarget }) => {
    const $target = $(currentTarget);
    $('.b-faq').removeClass('active');
    const className = $target.data('activate');
    $(`.b-faq.${className}`).addClass('active');
    $('.questions .contents li').removeClass('active');
    $target.addClass('active');
  });
});

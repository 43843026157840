import ComposableForm from '@/views/composable_form';
import PhoneArea from '@/views/phone_area';
import PrivacyNote from '@/views/privacy_note';
import track from '@/helpers/track';

pageLoad('guest_access_new', 'guest_access_create', () => {
  new ComposableForm('.b-form', {
    fields: ['#profile_name'],
    components: [
      new PhoneArea('.b-phone_area'),
      new PrivacyNote('.b-privacy_note')
    ]
  });

  $('form').on('submit', () => {
    track('guest_visit');
  });
});

import ComposableForm from '@/views/composable_form';
import PhoneArea from '@/views/phone_area';
import PrivacyNote from '@/views/privacy_note';

pageLoad(
  'registrations_new',
  'registrations_create',
  'registrations_show',
  'registrations_update', () => {
    // to skip v2 pages
    if (!document.body.className.includes('p-orders-registrations')) { return; }

    new ComposableForm('.b-form', {
      fields: ['#user_email', '#user_password'],
      components: [
        new PhoneArea('.b-phone_area'),
        new PrivacyNote('.b-privacy_note')
      ]
    });
  });

pageLoad('sessions_new', 'sessions_show', 'sessions_create', () => {
  // to skip v2 pages
  if (!document.body.className.includes('p-orders-sessions')) { return; }

  new ComposableForm('.b-form', {
    fields: ['#user_email', '#user_password']
  });
});

import WorldMap from '@/views/world_map';
import { buildMap } from '@/views/map';

let map = null;

$(document).on('turbolinks:before-cache', () => {
  if (map) {
    map.destroy();
    map = null;
  }
});

pageLoad('pages_root', () => {
  if (!document.body.className.includes('v4')) { return; }

  const node = document.querySelector('article.slide_4');
  const mapNode = node.querySelector('.b-map');

  if (mapNode) {
    const observer = new IntersectionObserver(observerCallback, {
      rootMargin: '40px',
      threshold: [0, 1]
    });
    observer.observe(mapNode);
  }

  const worldMapNode = node.querySelector('.b-world-map');
  if (worldMapNode) {
    new WorldMap(worldMapNode);
  }
});

async function observerCallback([entry], observer) {
  if (!entry.isIntersecting) { return; }
  observer.unobserve(entry.target);

  map = (await buildMap({
    node: entry.target,
    isAutoCenter: false,
    leadProps: $(entry.target).data('lead-props')
  })).map;
}

import bind from 'bind-decorator';
import View from './view';

export default class ArticleSlider extends View {
  initialize() {
    this.initSwiper();
    $(document).one('turbolinks:before-cache', this.scheduledDestruction);
  }

  async initSwiper() {
    const innerNode = this.node.querySelector('.inner');
    const swiperNode = this.node.querySelector('.swiper-v8');

    const [{ default: Swiper }, { Navigation, Pagination }] = await Promise.all([
      import('swiper'),
      import('swiper/modules')
    ]);

    const swiper = new Swiper(swiperNode, {
      wrapperClass: 'slides',
      slideClass: 'slide',
      grabCursor: false,
      allowTouchMove: true,
      slidesPerView: 1,
      spaceBetween: 0,
      modules: [Navigation, Pagination],
      navigation: {
        nextEl: '.b-slider-next',
        prevEl: '.b-slider-prev'
      },
      pagination: {
        el: '.b-slider-pagination',
        modifierClass: 'slider-pagination-',
        bulletClass: 'slider-pagination-bullet',
        bulletActiveClass: 'slider-pagination-bullet-active',
        clickable: true
      },
      loop: true,
      on: {
        slideChange() {
          const INDEX_ATTRIBUTE = 'data-swiper-slide-index';
          const index = parseInt(
            this.slides[this.activeIndex].getAttribute(INDEX_ATTRIBUTE)
          );
          const slidesLoop = parseInt(innerNode.getAttribute('data-slides-loop'));

          innerNode.setAttribute(
            'data-slide',
            isNaN(slidesLoop) ? index + 1 : (index % slidesLoop) + 1
          );
        }
      }
    });
    this.swiper = swiper;
    this.swiper.changeLanguageDirection(document.documentElement.getAttribute('dir'));
  }

  @bind
  scheduledDestruction() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
  }
}

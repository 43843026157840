import delay from 'delay';
import bind from 'bind-decorator';

import PhoneWithCountryCode from './phone_with_country_code';
import axios from '@/helpers/axios';

export default class PhoneArea extends PhoneWithCountryCode {
  initialize(isAllowEmptyCode, isSkipConfirmation) {
    super.initialize();

    this.isAllowEmptyCode = isAllowEmptyCode;
    this.isSkipConfirmation = isSkipConfirmation;

    this.$confirmationCode = this.$('.code-area input');
    this.$sendConfirmation = this.$('.send-confirmation');

    this.isSent = !!(
      this.$('.error-message.invalid-code').length || // if have error
        (this.confirmationCode && !this.$('.error-message.invalid-code').length) // or if have code set and have no error
    );
    this.isPhoneChanged = this.isSent;
    this.prevPhone = this.phoneNumber;

    this.$phoneNumber.on('change keyup', this.onPhoneChanged);
    this.$phoneNumber.on('paste', () => delay().then(this.onPhoneChanged));

    this.$('.switch-to-phone').on('click', this.onSwitchToPhone);
    this.$sendConfirmation.on('click', this.onPhoneSubmit);

    this.updateState({});
  }

  get isValid() {
    return !!(
      this.isAllowEmptyCode ?
        (this.phoneNumber && (this.isSkipConfirmation || this.isSent || this.confirmationCode)) :
        this.confirmationCode
    );
  }

  get phoneNumber() {
    return this.$phoneNumber.val();
  }

  get countryCode() {
    return this.$countryCode.val();
  }

  get confirmationCode() {
    return this.$confirmationCode.val();
  }

  @bind
  onPhoneChanged() {
    if (this.prevPhone !== this.phoneNumber) {
      this.isPhoneChanged = true;
      this.isSent = false;

      this.resetConfirmationCode();
    }

    this.updateState({});
  }

  @bind
  onCodeChanged({ type, keyCode }) {
    this.updateState();

    if (type === 'keyup' && keyCode === 13 && this.code) {
      this.$node.submit();
    }
  }

  @bind
  onSwitchToPhone() {
    this.isSent = false;
    this.$('.error-message.invalid-code').remove();
    this.updateState({});
  }

  @bind
  async onPhoneSubmit(e) {
    if (!this.isPhoneChanged || this.isSent) { return; }
    if (this.phoneNumber === '') { return; }

    e.preventDefault();
    e.stopImmediatePropagation();

    this.$sendConfirmation.prop('disabled', true);

    const { data } = await this.submitPhone();

    if (data.is_sent) {
      this.isSent = true;
      this.prevPhone = this.phoneNumber;
      this.$('.error-message.cant-send').addClass('hidden');
    } else {
      this.$('.error-message.cant-send').removeClass('hidden');
    }

    await delay(1);
    this.updateState({ isFocus: true });
  }

  submitPhone() {
    const url = this.$sendConfirmation.data('url');
    const phone = this.phoneNumber;

    return axios.post(url, { phone });
  }

  resetConfirmationCode() {
    this.$confirmationCode.val('');
  }

  updateState({ isFocus }) {
    this.$sendConfirmation.prop(
      'disabled',
      !this.isPhoneChanged || this.isSent || !this.phoneNumber
    );

    this.$('.code-area').toggleClass('hidden', !this.isSent);
    this.$sendConfirmation.toggleClass('hidden', this.isSent);

    if (isFocus && this.isSent) {
      this.$confirmationCode.focus();
    }

    this.trigger('component:update');
  }
}

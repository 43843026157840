import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { toCannotBeStored } from './cannot_be_stored';
import { toLandingLeadForm } from './landing_lead_form';

$(document).on('turbolinks:load', () => {
  const node = document.querySelector('article.how_to_rent_box');
  if (!node) { return; }

  $('.see-more', node).on('click', togglePopup);
  $('.show-more', node).on('click', toCannotBeStored);
  $('.popup .close', node).on('click', togglePopup);
  $('.popup .learn-more', node).on('click', async () => {
    togglePopup();
    toLandingLeadForm();
  });
});

function togglePopup() {
  const $popup = $('article.how_to_rent_box .popup');

  $popup.toggleClass('is-active');

  if ($popup.hasClass('is-active')) {
    disablePageScroll();
  } else {
    enablePageScroll();
  }
}

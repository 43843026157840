import memoize from 'memoize-decorator';
import bind from 'bind-decorator';

import View from './view';

const DATA_FIELD = 'switcher_type';
const ACTIVE_CLASS = 'is-active';

export default class Switcher extends View {
  // callback - если нужно что-то сделать после переключения.
  // slidesNodeSelector - если слайды идут не сразу после переключателя.
  initialize(callback = null, slidesNodeSelector = null) {
    this.callback = callback;
    this.slidesNodeSelector = slidesNodeSelector;
    this.$buttons = this.$node.find(`[data-${DATA_FIELD}]`);
    this.type = this.$buttons.filter(`.${ACTIVE_CLASS}`).data(DATA_FIELD);

    if (!this.type) {
      const types = this.$buttons
        .toArray()
        .map(node => node.getAttribute(`data-${DATA_FIELD}`));
      this.switch(types[0]);
    }

    this.$buttons.on('click', this.onClick);
  }

  @memoize
  get $siblings() {
    if (this.slidesNodeSelector) {
      return $(this.slidesNodeSelector);
    }
    return this.$node.siblings();
  }

  @memoize
  get isTransparentSwitch() {
    return this.$node.hasClass('b-switcher--transparent_switch');
  }

  @bind
  onClick(e) {
    const $node = $(e.currentTarget);
    const newType = $node.data(DATA_FIELD);
    e.preventDefault(); // Избегаем submit-а в случае, если переключатель в <form>

    if (newType !== this.type) {
      this.switch(newType);
    }
  }

  switch(newType) {
    const { $node, $siblings } = this;
    const $currentButton = $node.find(`[data-${DATA_FIELD}=${this.type}]`);
    const $nextButton = $node.find(`[data-${DATA_FIELD}=${newType}]`);
    const $currentSlide = $siblings.find(`[data-${DATA_FIELD}=${this.type}]`);
    const $nextSlide = $siblings.find(`[data-${DATA_FIELD}=${newType}]`);

    this.type = newType;

    if (this.isTransparentSwitch) {
      this.switchTransparently(
        $currentButton, $nextButton, $currentSlide, $nextSlide
      );
    } else {
      this.switchInstantly(
        $currentButton, $nextButton, $currentSlide, $nextSlide
      );
    }

    if (this.callback) {
      this.callback(newType);
    }
  }

  switchTransparently($currentButton, $nextButton, $currentSlide, $nextSlide) {
    $currentSlide.css({
      height: $currentSlide.outerHeight(),
      width: $currentSlide.outerWidth()
    });
    $nextSlide.css({
      height: '',
      width: ''
    });

    this.switchInstantly(
      $currentButton, $nextButton, $currentSlide, $nextSlide
    );
  }

  switchInstantly($currentButton, $nextButton, $currentSlide, $nextSlide) {
    $currentButton.removeClass(ACTIVE_CLASS);
    $nextButton.addClass(ACTIVE_CLASS);

    $currentSlide.removeClass(ACTIVE_CLASS);
    $nextSlide.addClass(ACTIVE_CLASS);

    $nextSlide.trigger('switcher:active');
  }
}

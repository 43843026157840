pageLoad('photos_new', async () => {
  const node = document.querySelector('.p-photos');
  if (!node) { return; }

  const { default: Uploader } = await import(
    /* webpackChunkName: "gallery_uploader" */ '@/admin/views/uploader'
  );
  const { default: Gallery } = await import(
    /* webpackChunkName: "gallery_uploader" */ '@/admin/views/gallery'
  );

  const gallery = new Gallery();
  const uploader = new Uploader({
    $uploadButton: $('#upload'),
    $targetInput: $('#profile_image_image'),
    presignUrl: '/selfstorage/profile_images/cache/presign/',
    isPdf: true
  });

  UpdatePhotoContext();

  $('.b-gallery').on('ajax:before', '.delete', e => {
    gallery.remove(
      $(e.target).closest('.b-collection_image_item')
    );
    UpdatePhotoContext();
  });

  $('#new_profile_image').on('ajax:success', (e, { html, error }) => {
    $('#upload').removeClass('btn-loading');

    if (error) {
      alert(error);
    } else {
      gallery.add(html);
      UpdatePhotoContext();
    }
  });

  const { default: Webcam } = await import(
    /* webpackChunkName: "gallery_uploader" */ 'webcamjs'
  );
  $('#webcam_enable').on('click', () => {
    $('#webcam_enable').hide();
    $('#upload').hide();
    $('.camera').removeClass('hidden');

    Webcam.set({
      width: 640,
      height: 480,
      image_format: 'jpeg',
      jpeg_quality: 90
    });
    Webcam.attach('.camera .display');
  });

  $('#webcam_snapshot').on('click', () => {
    Webcam.snap(dataUri => {
      uploader.submitDataUri(dataUri);
      $('#webcam_cancel').click();
    });
  });

  $('#webcam_cancel').on('click', () => {
    Webcam.reset();
    $('#webcam_enable').show();
    $('#upload').show();
    $('.camera').addClass('hidden');
  });
});

function UpdatePhotoContext() {
  const minImages = parseInt($('.progress-bar').data('min-photos'));
  const images = $('.b-collection_image_item').length;
  $('input.submit').prop('disabled', images < minImages);
  $('#uploaded_images').text(images);

  const $progressBar = $('.progress-bar .progress');
  $progressBar
    .removeClass('w-0')
    .removeClass('w-33')
    .removeClass('w-50')
    .removeClass('w-66')
    .removeClass('w-100');

  if (images === 0) { $progressBar.addClass('w-0'); }
  if (minImages > 2) {
    if (images === 1) { $progressBar.addClass('w-33'); }
    if (images === 2) { $progressBar.addClass('w-66'); }
    if (images >= 3) { $progressBar.addClass('w-100'); }
  } else {
    if (images === 1) { $progressBar.addClass('w-50'); }
    if (images >= 2) { $progressBar.addClass('w-100'); }
  }
}

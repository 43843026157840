import makeVueModal from '@/views/modal/make_vue_modal';
let modal = null;

$(document).on('turbolinks:before-cache', () => {
  if (modal) {
    modal.destroy();
    modal = null;
  }
});

$(document).on('turbolinks:load', async () => {
  if (!document.body.className.includes('v4')) { return; }

  $('footer.b-footer.v4 button.lead').one('click', async ({ currentTarget }) => {
    const { default: vueComponent } = await import('@/vue/modals/call_lead');
    modal ||= makeVueModal({
      trigger: currentTarget,
      vueComponent,
      isCloseOnSubmit: true
    });
  });
});

import { buildMap } from '@/views/map';
import { initStorages, initPhotos } from './pages-root';
import { initExpandableList } from './pages-personal_business_storage';

import { isMobile } from '@/helpers/mobile_detect';

let map = null;
let storagesSwiper = null;
let photosSwiper = null;
let roomsSwiper = null;

pageUnload('info_pages_show', () => {
  if (map) {
    map.destroy();
    map = null;
  }

  if (storagesSwiper) {
    storagesSwiper.destroy();
    storagesSwiper = null;
  }
  if (photosSwiper) {
    photosSwiper.destroy();
    photosSwiper = null;
  }
  if (roomsSwiper) {
    roomsSwiper.destroy();
    roomsSwiper = null;
  }
});

pageLoad('info_pages_show', async () => {
  photosSwiper = initPhotos();
  initExpandableList();

  if (isMobile()) {
    storagesSwiper = initStorages();
    const { default: Swiper } = await import('swiper');

    roomsSwiper = new Swiper('.rooms', {
      wrapperClass: 'fc-rooms',
      slideClass: 'f-column',
      grabCursor: true,
      slidesPerView: 'auto',
      spaceBetween: 0
    });
  } else {
    const $map = $('#map_desktop');
    if ($('#map_desktop').length) {
      map = (await buildMap({
        node: $map[0],
        isAutoCenter: false,
        leadProps: $map.data('lead-props')
      })).map;
    }
  }
});

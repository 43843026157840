import { disablePageScroll, enablePageScroll } from 'scroll-lock';
let isMenuActive = false;
import SweetScroll from '@/helpers/sweet_scroll';

$(document).on('turbolinks:load', () => {
  if (!document.body.className.includes('v3')) { return; }

  const $topMenu = $('.b-top_menu.v3');

  $('.mobile-trigger', $topMenu).on('click', toggleMenu);
  $('.consultation', $topMenu).on('click', async () => {
    (await SweetScroll.asyncInstance()).toElement(
      document.querySelector('article.landing_lead_form'), { duration: 750 }
    );
  });

  $('.b-locale_switcher-v3 .checkbox-current-locale', $topMenu)
    .on('click', async ({ currentTarget }) => {
      const { localeSwitcherV3Modal } = await import(
        /* webpackChunkName: "modal" */
        '@/views/modal/locale_switcher_v3_modal'
      );
      const $target = $(currentTarget);
      localeSwitcherV3Modal(
        $target.data('countries'),
        $target.data('locales'),
        $target.data('default-locale'),
        $target.data('current-country'),
        $target.data('current-locale')
      );
    });
});

function toggleMenu() {
  isMenuActive = !isMenuActive;
  $('.b-top_menu, .b-top_menu-v3').toggleClass('is-mobile-active', isMenuActive);

  if (isMenuActive) {
    disablePageScroll();
    window.scrollTo(0, 0);
  } else {
    enablePageScroll();
  }
}

import trackVk from '@/helpers/track_vk';

export default function(event, ecommerce) {
  if (window.ENV === 'development') {
    console.log('track', event, ecommerce); // eslint-disable-line no-console
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(
    ecommerce ?
      { event, ecommerce } :
      { event }
  );

  if (window.location.host === 'kladovkin.ru') {
    trackVk(event);
  }
}

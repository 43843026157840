import { buildMap } from '@/views/map';
import TinyUri from 'tiny-uri';

let map = null;
let marks = null;
let currentStorageId = null;
let isIgnoreNextBalloonClose = false;

pageUnload('pages_delivery', () => {
  if (map) {
    map.destroy();
    map = null;
  }
});

pageLoad('pages_delivery', async () => {
  $('#storage_id').on('change', ({ currentTarget }) => {
    const storageId = parseInt(currentTarget.value);
    selectPlacemark(storageId);
  });

  const $map = $('#map');
  const mapData = (await buildMap({
    node: $map[0],
    isAutoCenter: false,
    mapType: 'ymap',
    leadProps: $map.data('lead-props')
  }));
  map = mapData.map;
  marks = mapData.marks;

  marks.forEach(mark => {
    mark.marker.events.add('click', e => {
      e.preventDefault();
      selectPlacemark(
        mark.storageId === currentStorageId ?
          null :
          mark.storageId
      );
    });

    mark.marker.balloon.events.add('close', () => {
      if (isIgnoreNextBalloonClose) {
        isIgnoreNextBalloonClose = false;
      } else {
        selectPlacemark(null);
      }
    });
  });
});

function selectPlacemark(storageId) {
  const $storage = $('#storage_id');
  const mark = marks.find(mark => mark.storageId === storageId);
  const currentMark = marks.find(mark => mark.storageId === currentStorageId);

  if (currentMark?.marker?.balloon?.isOpen()) {
    isIgnoreNextBalloonClose = true;
    currentMark?.marker?.balloon.close();
  }

  if (storageId) {
    mark.marker.balloon.open();
  }

  $storage.val(storageId == null ? 'null' : storageId);
  currentStorageId = storageId;

  updateWidget(mark);
}

function updateWidget(mark) {
  const $widget = $('.ya-taxi-widget');
  $widget.toggle(!!mark);

  if (!mark) { return; }

  const $link = $widget.find('a');
  const $description = $widget.find('.ya-taxi-widget__description');

  const url = $link.prop('href');

  $description.html(mark.markData.name);
  $link.prop(
    'href',
    new TinyUri(url)
      .query.set({
        'end-lat': mark.markData.latitude,
        'end-lon': mark.markData.longitude
      })
      .toString()
  );
}

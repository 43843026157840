import queryString from 'query-string';

import { throttle } from 'throttle-debounce';
import { isMobile } from '@/helpers/mobile_detect';

let lastScrollY;
const tolerance = 75; // Adjust this value as needed
let scrollBuffer;
let ticking;

let assistantApp;

$(document).one('turbolinks:load', () => {
  $(window).on('scroll', throttle(50, () => $(document.body).trigger('scroll:throttled')));
});

$(document).on('turbolinks:load', () => {
  $('.b-sticky-area .icon-expand').on('click', toggleDropdown);
  $('.b-sticky-area .dropdown.menu a').on('click', toggleDropdown);
  $('.b-sticky-area .overlay').on('click', disableDropdownOrAssistant);
  $('.b-sticky-area .dropdown-close').on('click', disableDropdownOrAssistant);

  $('.b-sticky-area .dropdown.menu .assistant, .b-sticky-area .icon-assistant')
    .on('click', toggleAssistant);

  $(window).on('scroll:throttled', controlStickyAreaByScroll);

  resetSrollLogic();

  const queryParams = queryString.parse(window.location.href.replace(/.*?(?:\?|$)/, ''));
  const isDebugMode = 'assistant-debug' in queryParams;
});

$(document).on('turbolinks:before-cache', () => {
  $('.b-sticky-area .icon-assistant').removeClass('is-active');
  $('.b-sticky-area .icon-expand').removeClass('is-active');
  $('.b-sticky-area .dropdown').removeClass('is-active');
  $('.b-sticky-area .overlay').removeClass('is-active');

  if (assistantApp) {
    assistantApp.unmount();
    assistantApp = null;
  }

  $(window).off('scroll:throttled', controlStickyAreaByScroll);
});

function disableDropdownOrAssistant() {
  if ($('.b-sticky-area .dropdown.menu.is-active').length) {
    toggleDropdown();
  }
  if ($('.b-sticky-area .dropdown.assistant.is-active').length) {
    toggleAssistant();
  }
}

function toggleDropdown() {
  $('.b-sticky-area .icon-expand').toggleClass('is-active');
  $('.b-sticky-area .dropdown.menu').toggleClass('is-active');
  $('.b-sticky-area .overlay').toggleClass('is-active');

  resetSrollLogic();
}

async function toggleAssistant() {
  if ($('.b-sticky-area .dropdown.menu.is-active').length) {
    toggleDropdown();
  }

  $('.b-sticky-area .icon-assistant').toggleClass('is-active');
  $('.b-sticky-area .overlay').toggleClass('is-active');
  $('.b-sticky-area .dropdown.assistant').toggleClass('is-active');

  if (assistantApp) {
    assistantApp.unmount();
    assistantApp = null;
  } else {
    const { createApp } = await import(/* webpackChunkName: "vue" */ 'vue');
    const { default: App } = await import('@/vue/assistant/index');

    const appNode = document.querySelector('#assistant_app');
    assistantApp = createApp(App, {
      chatUrl: appNode.getAttribute('data-assistant_url')
    });

    assistantApp.mount(appNode);
  }

  resetSrollLogic();
}

function resetSrollLogic() {
  lastScrollY = window.scrollY;
  scrollBuffer = 0;
}

function controlStickyAreaByScroll() {
  if ($('.b-sticky-area .icon-expand.is-active').length) { return; }
  if (!isMobile()) { return; }

  const currentScrollY = window.scrollY;
  const scrollDifference = currentScrollY - lastScrollY;

  if ((scrollBuffer > 0 && scrollDifference < 0) || (scrollBuffer < 0 && scrollDifference > 0)) {
    scrollBuffer = 0;
  }

  scrollBuffer += scrollDifference;

  if (Math.abs(scrollBuffer) >= tolerance) {
    if (scrollBuffer < 0) {
      $('.b-sticky-area').addClass('is-visible');
    } else {
      $('.b-sticky-area').removeClass('is-visible');
    }
    scrollBuffer = 0; // Reset buffer after applying the change
  }

  lastScrollY = currentScrollY;
}

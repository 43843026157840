import bind from 'bind-decorator';

import View from './view';
import axios from '@/helpers/axios';

let instance;

export default class SpellCheck extends View {
  initialize() {
    if (!this.node) { return; }

    instance = this;

    this.on('click', this.submit);
  }

  get selectedText() {
    return document.getSelection().toString().trim();
  }

  sync() {
    this.$node.toggleClass('is-active', !!this.selectedText);
  }

  @bind
  async submit() {
    const textCorrect = prompt(this.node.getAttribute('data-confirmation_text'));
    if (textCorrect == null) { return; }

    await axios.post(this.node.getAttribute('data-url'), {
      spell_check: {
        ...JSON.parse(this.node.getAttribute('data-params')),
        text_incorrect: this.selectedText,
        text_correct: textCorrect
      }
    });
    alert(this.node.getAttribute('data-thanks_text'));
  }
}

document.addEventListener('selectionchange', () => instance?.sync());

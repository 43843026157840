import SweetScroll from '@/helpers/sweet_scroll';

export async function toLandingLeadForm(e) {
  e?.preventDefault();

  (await SweetScroll.asyncInstance()).toElement(
    document.querySelector('article.landing_lead_form'),
    { duration: 750, offset: -40 }
  );
}

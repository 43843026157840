import { disablePageScroll, enablePageScroll } from 'scroll-lock';

$(document).on('turbolinks:load', () => {
  const node = document.querySelector('article.orders .order-details');
  if (!node) { return; }

  $('.simple-lock a.more-info').on('click', () => {
    disablePageScroll();
    window.scrollTo(0, 0);
    $('.container-bottom').addClass('hidden');
    $('.modal-simple-lock').removeClass('hidden');
  });

  $('a.modal-close').on('click', () => {
    enablePageScroll();
    $('.modal-simple-lock').addClass('hidden');
    $('.container-bottom').removeClass('hidden');
  });
});

import { initStorages, initPhotos } from './pages-root';

import { isMobile } from '@/helpers/mobile_detect';
import { animatedCollapse, animatedExpand } from '@/helpers/animated';

let storagesSwiper = null;
let photosSwiper = null;

pageUnload('pages_personal_storage', 'pages_business_storage', () => {
  if (storagesSwiper) {
    storagesSwiper.destroy();
    storagesSwiper = null;
  }
  if (photosSwiper) {
    photosSwiper.destroy();
    photosSwiper = null;
  }
});

pageLoad('pages_personal_storage', 'pages_business_storage', async () => {
  initExpandableList();

  if (isMobile()) {
    storagesSwiper = initStorages();
  }

  photosSwiper = initPhotos();
});

export function initExpandableList() {
  $('.expandable-list-block .f-navigation li').on('click', ({ currentTarget }) => {
    const $node = $(currentTarget);
    const $content = $('.f-content');
    const $li = $content.find('li');
    const height = parseInt($content.css('min-height')) || 0;

    $node.addClass('active');
    $node.siblings().removeClass('active');

    if (height < $content.height()) {
      $content.css('min-height', $content.height());
    }

    $li
      .removeClass('active')
      .eq($node.index())
      .addClass('active');
  });

  $('.expandable-list-block .f-content .mobile-toggler').on('click', async ({ currentTarget }) => {
    const $li = $(currentTarget).closest('li');
    const $activeLi = $('.expandable-list-block .f-content li.active');

    if (!$li.hasClass('active')) {
      $li.addClass('active');
      animatedExpand($li.find('.inner')[0]);
    }

    await animatedCollapse($activeLi.find('.inner')[0]);
    $activeLi.removeClass('active');

    $('.expandable-list-block .f-navigation li')
      .removeClass('active')
      .eq($li.index())
      .addClass('active');
  });
}

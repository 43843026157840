import Switcher from '@/views/switcher';

$(document).on('turbolinks:load', () => {
  const node = document.querySelector('article.storable_types');
  if (!node) { return; }

  new Switcher('article.storable_types .b-switcher');

  $('.show-more button', node).on('click', () => {
    $('.show-more', node).hide();
    $('.slides', node).addClass('is-mobile-expanded');
  });
});
